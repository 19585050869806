const getDatePlusRange = ({initialDate, monthToAdd, daysToAdd, h = 0, m = 0, s = 0, ms = 0, needConsiderTimeZone = true}) => {
    let date;
    if(initialDate){
        date =  new Date(initialDate) ;
        date.setHours(h, m, s, ms);
        if (needConsiderTimeZone) {
            const timeZone = date.getTimezoneOffset() * 60 * 1000;
            date = new Date(date.getTime() - timeZone)
        }
    }else{
        date = new Date();
        date.setHours(h, m, s, ms);
    }
    daysToAdd && date.setDate(date.getDate() + daysToAdd);
    monthToAdd && date.setMonth(date.getMonth() + monthToAdd);
    return date;
};

export default getDatePlusRange;